import * as types from '../actionTypes'
import functionalRoles from '../../data/functionalRole.json';
import functionalRolesC from '../../data/functionalRoleC.json';
import APIConstants from '../../utils/APIConstants';


const getFuncRolesObj = () => {
    let formatterArr = [];
    functionalRoles.forEach(item => {
        formatterArr.push({
            id: item.id,
            name: item.name,
            selected: true
        })
    })

    return formatterArr
}

const getFuncRolesObjC = () => {
    let formatterArr = [];
    functionalRolesC.forEach(item => {
        formatterArr.push({
            id: item.id,
            name: item.name,
            selected: true
        })
    })

    return formatterArr
}



const initialState = {
    listView: false,
    cardView: true,
    allFuncRolesDisplayed: true,
    displayedSections: [],
    selectedCollection: {},
    isMemberDisplayed: false,
    colId:0,
    jrId:0,
    payMonthly:false,
    activeJrId:0,
    
}


function filterViewReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIST_VIEW:
            return { ...state, cardView: false, listView: true }
        case types.SET_CARD_VIEW:
            return { ...state, cardView: true, listView: false }
        case types.TOGGLE_FUNC_ROLE_SELECTION_NEW:
            console.log("STATE", state, action.payload)
            let _indexOfSection = state.displayedSections.findIndex(item => item.id === action.payload.sectionId);
            console.log("_indexOfSection", _indexOfSection)
            let _newAllFunctionalRoles = [...state.displayedSections];

            console.log("_newAllFunctionalRoles", _newAllFunctionalRoles);
            console.log("action.payload.funcRoleid", action.payload.funcRoleid)

            let indexOfFuncRoleObj = _newAllFunctionalRoles[_indexOfSection].functionalRoleIDs.findIndex(item => item.id === action.payload.funcRoleid);
            console.log("indexOfFuncRoleObj", indexOfFuncRoleObj)

            _newAllFunctionalRoles[_indexOfSection].functionalRoleIDs = {
                ..._newAllFunctionalRoles[_indexOfSection].functionalRoleIDs,
                "displayed": !_newAllFunctionalRoles[_indexOfSection].functionalRoleIDs[indexOfFuncRoleObj].displayed
            }

            console.log("_newAllFunctionalRoles", _newAllFunctionalRoles)

            // return { ...state, displayedSections: _newAllFunctionalRoles }
            return { ...state }

        case types.TOGGLE_SECTION_DISPLAY:
            let indexOfSection = state.displayedSections.findIndex(item => item.id === action.payload);

            let newAllFunctionalRoles = [...state.displayedSections];
            newAllFunctionalRoles[indexOfSection] = { ...newAllFunctionalRoles[indexOfSection], "displayed": !newAllFunctionalRoles[indexOfSection].displayed }

            return { ...state, displayedSections: newAllFunctionalRoles }

        case types.SET_DISPLAYED_SECTIONS:
            return { ...state, displayedSections: [...action.payload] }

        case types.SET_SELECTED_COLLECTION:
            return { ...state, selectedCollection: { ...action.payload } }
        case types.JURI_SELECTION:
            return { ...state, colId:action.payload.colId,jrId:action.payload.jrId }
        case types.PAY_FILTER:
            return { ...state,payMonthly:action.payload}
        case types.ACTIVE_JR:
            return { ...state,activeJrId:action.payload}

        default:
            return state
    }
}





export default filterViewReducer