import * as types from '../actionTypes'

const initialState = {
    bannerDisplayed: true,
    filterType: "",
    selectedCollectionID: "",
    selectedJrsID: "",
    selectedFunctionalRoleID: "",
    modalDisplayed: false,
    modalType: "",
    modalData: {},
    chkbtn: false

}

function otherReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLOSE_TOP_BANNER:
            return { ...state, bannerDisplayed: false }
        case types.FILTER_COURSE:
            if (action.payload.filterType === "collection") {
                return { ...state, filterType: action.payload.filterType, selectedCollectionID: action.payload.filterTypeID, selectedJrsID: "", selectedFunctionalRoleID: "" }
            }

            if (action.payload.filterType === "jurisdiction") {
                return { ...state, filterType: action.payload.filterType, selectedJrsID: action.payload.filterTypeID }
            }

            if (action.payload.filterType === "functional-role") {
                return { ...state, filterType: action.payload.filterType, selectedFunctionalRoleID: action.payload.filterTypeID }
            }
        case types.OPEN_MODAL:
            return { ...state, modalDisplayed: true, modalType: action.payload.modalType, chkbtn:action.payload.chkbtn, modalData: action.payload.modalData }
        case types.CLOSE_MODAL:
            return { ...state, modalDisplayed: false, modalType: "", modalData: {} }
        case types.ERROR_MODAL:
            return { ...state, modalDisplayed: true, modalType: action.payload.modalType, modalData: action.payload.modalData }
        default:
            return state
    }
}

export default otherReducer