import CEStdTracks from './cestd_tracks.json';
import FArea from './../functionalRole.json';


export const CEStdCourseFilter = () =>{

	const STRCONSTANTS = {
		PRC_TTLS : [{id:100, name:'U.S. Export Compliance Certification Series'},
		{id:107, name:'U.S. Certification Series for Non-U.S. Practitioners'},
					{id:101, name:'U.S. Export Compliance Certification Series for UK Practitioners'}
					]
	}

	const getCourseStack = (courseData) =>{
		// console.log(StdConstants,CEStdTracks,FArea)

		const stdjr = Object.assign([],CEStdTracks.jurisdiction)

		/*
		all collections and adding jurisdiction to object
		*/
		let courseRack = Object.assign([],CEStdTracks.stdcollections)
		courseRack.map(cr => { return cr.jurisdiction = [] })

		/*
		Key,value for collections,
		keeping it static, should be created dynamically from CEStdTracks.stdcollections
		*/
		let cestack = {"1":[],"2":[],"3":[],"4":[],"5":[]}
		let a_stdjr = {}
		stdjr.forEach(jr =>{ a_stdjr[jr.id] = jr; })
		

		/*
		storing courses in respective collections
		*/
		courseData.forEach(item => {
			if(item.supplierName === undefined)	{
				cestack[item.collection].push(item)
			}
			
		})

		let grpId = -1
		Object.keys(cestack).forEach(item => {
			/*
			finding collection
			*/
			let col = courseRack.find(cl => { return (Number(cl.id) === Number(item)) });


			/** Need to ReLook this area */
			// if(item === '5'){
			// 	stdjr.forEach(jr =>{ 
			// 		if(jr.id === 104 || jr.id === 105){
			// 			jr.name = jr.name.charAt(0).toUpperCase() + jr.name.slice(1)
			// 			let Ijrs = {...jr}
			// 			Ijrs.stack = []
			// 			col.jurisdiction.push(Ijrs)
			// 		} 
			// 	});

			// 	courseData.forEach((el)=>{
			// 		/** only Medical added */
			// 		if(el['shopifyOneTimeHandle'] && el['shopifyOneTimeHandle'] === "medical-pilot-training"){
			// 			let grId = el.courseGroupId
			// 			const crs ={name:el.cname, id:el.id,gprid:grId,cid:el.cid,...el}
			// 			col.jurisdiction[0]['stack'].push(crs)
			// 		}
			// 	})
			// }
		   /** Need to ReLook this area */
		

			cestack[item].forEach(course => {
				/*
				checking jurisdiction is present
				*/
				let jrs = col.jurisdiction.find(jrs => {return Number(jrs.id) === Number(course.jrs)})
				if(jrs === undefined)  {
					jrs = Object.assign({},a_stdjr[course.jrs]) ;
					jrs.stack = []
					col.jurisdiction.push(jrs)

					// console.log(jrs)

				}

					/*
					iterating courses in collection
					*/
				

				/*
				Companion collections groupId to -1
				*/
				grpId = ( (course.courseGroupId === null) ? -1 : course.courseGroupId)

				// push the whole course object, keeping minimal for review 
				const crs ={name:course.cname, id:course.id,gprid:grpId,cid:course.cid,...course}

				jrs.stack.push(crs)
			})

		})

		/**
		* restructing stacks
		* pulling only handles for suggesions
		**/
		let suggested = []
		courseRack.forEach(item =>  {
			item.count = 0
		 item.jurisdiction.map(st => {
		  let courseDist = distributeOnRack(item.id, st)
		  st.stack = courseDist.track;
		  item.count += courseDist.ccount;
		  /**
		  * suggestion list for functional areas
		  * while its array, consider this as funational area
		  **/
		  if(Array.isArray(courseDist.suggested))	{
		  	courseDist.suggested.forEach(i =>{ suggested.push( i )})
		  }
		  else	{
		  	suggested.push( courseDist.suggested )
		  }
		 })
		})

		/**
	Bad implementation of swap added need to recheck
	- Non US section in practitioner has to be displayed in 2nd position
	**/

		courseRack.forEach(item =>  {
			if(item.id ==2 && item.name === "U.S. Export Compliance Certification Series"){
				if(item.jurisdiction[1] !== undefined && item.jurisdiction[2] !== undefined){
					let temp = item.jurisdiction[1];
					item.jurisdiction[1] = item.jurisdiction[2];
					item.jurisdiction[2] = temp;
				}
				
			}
		})

		

		return ({rack : courseRack, suggested: suggested})

	} 


	/**
	distributing courses on its respective racks
	for professional collection, stacking into functional areas
	**/

	const distributeOnRack = (col,list) => {
		/*
		Key,value for collections and functions
		*/

		let a_farea = {}
		let count = 0
		let farea =  Object.assign([],FArea)
		farea.forEach(fa =>{ a_farea[fa.id] = fa; })

		let crsrack = []
		let suggested = []
		switch(col) {
			case 1:
			crsrack = []

			list.stack.forEach(item =>  {
				let fid = crsrack.find(crs => {return Number(item.gprid) === Number(crs.id)})
				if(fid === undefined)  {
					// sugguested.push( ids.toString()  )
					fid = {...a_farea[item.gprid], courses:[]}
					crsrack.push(fid)


				}
				fid.courses.push(item); count ++

			})
			suggested = crsrack.map(item =>{  return item.courses.map(c =>{ return c.shopifyHandle}).toString() })
			break;

			case 2: 
				// statically added to stack
				let prcName = STRCONSTANTS.PRC_TTLS.find(prc => { return Number(prc.id) === Number(list.id)})
				prcName.courses = list.stack;
				suggested = Object.assign(list.stack,[]).map(item => {return item.shopifyHandle}).toString();
				count = list.stack.length
				crsrack.push(prcName)
			break;

			case 3: crsrack = list.stack; 
				suggested = Object.assign(list.stack,[]).map(item => {return item.shopifyHandle}).toString();
				count = list.stack.length
			break;

			case 4: 
				
				crsrack = list.stack; 
				suggested = Object.assign(list.stack,[]).map(item => {return item.shopifyHandle}).toString();
				count = list.stack.length

			break;

			case 5: crsrack = list.stack; 
				suggested = Object.assign(list.stack,[]).map(item => {return item.shopifyHandle}).toString();	
				count = list.stack.length
			break;         
		}

		return {track:crsrack,ccount:count, suggested:suggested};
	}

	return {
		getCourseStack
	}


}


export default CEStdCourseFilter;