import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/style.scss";

import store from "./redux/store";
import * as fetchShopJSONActions from './redux/actions/fetchShopJSONActions';
import * as fetchCurrencyExchangeRatesActions from './redux/actions/fetchCurrencyExchangeRatesActions';
import * as partnerTypeActions from './redux/actions/partnerTypeActions';

store.dispatch(partnerTypeActions.setPartnerType(window.PARTNER_TYPE));
store.dispatch(fetchShopJSONActions.fetchShopJSONFromCMSCMS());
store.dispatch(fetchCurrencyExchangeRatesActions.fetchCurrencyExchangeRatesActions());


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);