import * as types from '../actionTypes'

const initialState = {
    partnerRelationObj: [],
    supplierContentObj: [],
    loading: false,
    apiSuccess: false
}

function fetchPartnerRelationReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_SHOP_JSON_REQUEST:
            return { ...state, loading: true }
        case types.FETCH_SHOP_JSON_SUCCESS:
            return { partnerRelationObj: action.payload.partnerRelationObj, supplierContentObj: action.payload.supplierContentObj, loading: false, apiSuccess: true }
        case types.FETCH_SHOP_JSON_FAILURE:
            return { partnerRelationObj: [], apiSuccess: false, loading: false }
        default:
            return state
    }
}


export default fetchPartnerRelationReducer