import * as types from '../actionTypes'

const initialState = {
    currencyExchangeRateObj: [],
    selectedCurrency: "USD",
    allCurrencyCodes: [
        {
            id: 1,
            code: "USD",
            displayedName: "USD",
            symbol: "$",
            flagImage: require("../../assets/imgs/currency-flags/USD.jpg")
        },
        {
            id: 2,
            code: "GBP",
            displayedName: "GBP",
            symbol: "£",
            flagImage: require("../../assets/imgs/currency-flags/GBP.jpg")
        },
        {
            id: 3,
            code: "EUR",
            displayedName: "Euro",
            symbol: "€",
            flagImage: require("../../assets/imgs/currency-flags/EUR.jpg")
        }
    ],
    loading: false,
    apiSuccess: false
}

function fetchCurrencyExchangeRatesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_CURRENCY_EXCHANGE_RATES_REQUEST:
            return { ...state, loading: true }
        case types.FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS:
            return { ...state, currencyExchangeRateObj: action.payload, loading: false, apiSuccess: true }
        case types.FETCH_CURRENCY_EXCHANGE_RATES_FAILURE:
            return { ...state, currencyExchangeRateObj: [], apiSuccess: false, loading: false }
        case types.SET_SELECTED_CURRENCY:
            return { ...state, selectedCurrency: action.payload,selectedCFlag:action.flag }
        default:
            return state
    }
}


export default fetchCurrencyExchangeRatesReducer