const forgotPasswordTags = {
    "title": "Forgot Password",
    "description": "If you have forgotten your password you can reset it at any time."
}

const loginTags = {
    "title": "Log In",
    "description": "Logs in the user to the site.",
    "canonical": "/login"
}

const logoutTags = {
    "title": "Log Out",
    "description": "Logs out the user from the site."
}

const resetPasswordTags = {
    "title": "Reset Password",
    "description": "Please enter your email address below and we will send you an email message with a link to reset your password."
}

const signUpTags = {
    "title": "Sign Up",
    "description": "User sign up page.",
    "canonical": "/sign-up"
}

const contactUsTags = {
    "title": "Contact us",
    "description": "We’ll only use your personal information to administer your account and to provide the products and services you requested from us.",
    "canonical": "/contact-us"
}

const customerSupportTags = {
    "title": "Customer Support",
    "description": "Our customer success team is standing by to provide you with the help you need."
}

const FAQTags = {
    "title": "FAQ",
    "description": "Frequently asked questions",
    "canonical": "/faq"
}

const ourPartnersTags = {
    "title": "Our Partners",
    "description": "Content Enablers is proud to partner with the leading global institutions.",
    "canonical": "/our-partners"
}

const privacyPolicyTags = {
    "title": "Privacy Policy",
    "description": "CONTENT ENABLERS, INC respects individual privacy and values the confidence of its customers, employees, business partners and others.",
    "canonical": "/privacy-policy"
}

const termsConditionsTags = {
    "title": "Terms and Conditions",
    "description": "The materials presented on this website are provided by Content Enablers, Inc. for informational purposes only. Use of this website is subject to the following terms and conditions. You agree to these terms and conditions by accessing this website.",
    "canonical": "/terms-conditions"
}
const LoginDetails = {
    "title": "Login Details",
    "description": "The materials presented on this website are provided by Content Enablers, Inc. for informational purposes only. Use of this website is subject to the following terms and conditions. You agree to these terms and conditions by accessing this website.",
    "canonical": "/free-subscription"
}

const movementOfGoodsTags = {
    "title": "Movement of Goods Series",
    "description": "100% online subscription-based trade compliance training covering the U.S. EAR, ITAR, Import and Export Regulations, Sanctions and Embargoes, Anti-corruption, and a variety of other international trade related topics. Subscription plans available for individuals and businesses.",
    "canonical": "/"
}

const partnerLandingTags = {
    "description": "100% online subscription-based trade compliance training covering the U.S. EAR, ITAR, Import and Export Regulations, Sanctions and Embargoes, Anti-corruption, and a variety of other international trade related topics. Subscription plans available for individuals and businesses.",
    "canonical": "/"
}

const supplierCoursesTags = {
    "title": "Explore Courses",
    "description": "Collection of professional amd practitioner courses.",
    "canonical": "/courses"
}



const cartTags = {
    "title": "Cart",
    "description": "Collection of purchased courses."
}

const coursesTags = {
    "title": "Explore Courses",
    "description": "Collection of professional amd practitioner courses.",
    "canonical": "/courses"
}


// const ShopLandingTags = [
//     {
//         "partnerType": "shop",
//         "canonical": "https://shop.contentenablers.com",
//         "msvalidate": "",
//         "title": "Online Trade Compliance Training Courses | Content Enablers",
//         "description": "100% online subscription-based trade compliance training covering the U.S. EAR, ITAR, Import and Export Regulations, Sanctions and Embargoes, Anti-corruption, and a variety of other international trade related topics. Subscription plans available for individuals and businesses",
//         "canonical": ""
//     },
//     {
//         "partnerType": "shop-landing",
//         "canonical": "https://tradecompliance.courses/",
//         "msvalidate": "FAB955226E1958A40C825272E505F010",
//         "title": "On-Demand Trade Compliance Training Courses | Content Enablers",
//         "description": "100% online subscription-based trade compliance training covering the U.S. EAR, ITAR, Import and Export Regulations, Sanctions and Embargoes, Anti-corruption, and a variety of other international trade related topics. Subscription plans available for individuals and businesses.",
//         "canonical": ""
//     }
// ]

const myAccountTags = {
    "title": "My Account",
    "description": "User Profile Details"
}

const notFoundTags = {
    "title": "Page Not Found",
    "description": "Page does not exist."
}

const practitionerBundleCourseDetailTags = {
    "description": "Designed to suit the specific training needs of trade compliance professionals, this collection presents the kind of in-depth, practitioner-level content required to ensure that they're prepared to address the complex, ever-changing international trade rules and regulations that companies face.",
}

const practitionerCourseDetailTags = {
    "title": "Practitioner Collection",
    "description": "Designed to suit the specific training needs of trade compliance professionals, this collection presents the kind of in-depth, practitioner-level content required to ensure that they're prepared to address the complex, ever-changing international trade rules and regulations that companies face."
}

const practitionerLandingTags = {
    "title": "Explore Courses",
    "description": "Collection of professional amd practitioner courses.",
    "canonical": "/trade-compliance-practitioner-series"
}

const professionalCourseDetailTags = {
    "title": "Professional Collection",
    "description": "This collection has everything you need to provide comprehensive role-specific trade compliance training for all your professional staff members anywhere in the world who are involved in international transactions or travel, ensuring that they can conduct business compliantly and efficiently."
}

const searchResultTags = {
    "title": "Search Result",
    "description": "Serach option shows the result from U.S. EAR, ITAR, Import and Export Regulations, Sanctions and Embargoes, Anti-corruption, and a variety of other international trade related topics."
}

module.exports = {
    forgotPasswordTags,
    loginTags,
    logoutTags,
    resetPasswordTags,
    signUpTags,
    contactUsTags,
    customerSupportTags,
    FAQTags,
    ourPartnersTags,
    privacyPolicyTags,
    termsConditionsTags,
    movementOfGoodsTags,
    partnerLandingTags,
    supplierCoursesTags,
    cartTags,
    coursesTags,
    // ShopLandingTags,
    myAccountTags,
    notFoundTags,
    practitionerBundleCourseDetailTags,
    practitionerCourseDetailTags,
    practitionerLandingTags,
    professionalCourseDetailTags,
    searchResultTags
} 