import * as types from "../actionTypes";

export const closeTopBanner = () => {
    console.log("closeTopBanner")
    return {
        type: types.CLOSE_TOP_BANNER
    }
}

// for /all-course page (used for debugging/testing)
export const filterCourse = (filterType, filterTypeID) => {
    return {
        type: types.FILTER_COURSE,
        payload: { filterType, filterTypeID }
    }
}

export const closeModal = () => {
    console.log("Close Modal")
    return {
        type: types.CLOSE_MODAL
    }
}

export const openModal = (modalType, chkbtn, modalData = {}) => {
    console.log("Open Modal")
    return {
        type: types.OPEN_MODAL,
        payload: { modalType, chkbtn, modalData }
    }
}

export const errorModal = (modalType, modalData = {}) => {
    console.log("Error Modal")
    return {
        type: types.ERROR_MODAL,
        payload: { modalType, modalData }
    }
}

